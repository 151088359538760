/* Framework imports -------------------------------------------------------- */
import styled from '@emotion/styled'

/* Type imports ------------------------------------------------------------- */
import {
  getChipColor,
  type ChipColor,
} from 'types/ChipColor'

/* Component declaration ---------------------------------------------------- */
export interface ColoredSquareChipProps {
  variant?: 'outlined' | 'filled';
  color: ChipColor;
  customColor?: string | null;
  textColor?: string;
  bold?: boolean;
  smaller?: boolean;
}

const ColoredSquareChip = styled.div<ColoredSquareChipProps>`
  background: ${(props) => props.variant === 'outlined' ? props.theme.colors.main : getChipColor(props.color, props.customColor || '', props.theme)};
  color: ${(props) => props.variant === 'outlined' ? getChipColor(props.color, props.customColor || '', props.theme) : props.textColor || '#000000'};
  border: ${(props) => props.variant === 'outlined' ? `2px solid ${getChipColor(props.color, props.customColor || '', props.theme)}` : ''};
  font-weight: ${(props) => props.bold ? 'bold' : 'initial'};
  text-align: center;
  align-self: center;
  width: fit-content;
  min-width: 40px;
  height: auto;
  border-radius: ${(props) => props.smaller ? '3px' : '4px'};
  padding: ${(props) => props.smaller ? '3px' : '5px'} 7px;
  font-size: ${(props) => props.smaller ? '12px' : '14px'};
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: normal;
`

export default ColoredSquareChip
