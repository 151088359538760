/* Type imports ------------------------------------------------------------- */
import type { Theme } from '@emotion/react'

/* Type declarations -------------------------------------------------------- */
export type ChipColor = 'green' |
                        'orange' |
                        'darkorange' |
                        'red' |
                        'pink' |
                        'grey' |
                        'salmon' |
                        'yellow' |
                        'purple' |
                        'lightblue' |
                        'blue' |
                        'primary' |
                        'secondary'

const isValidHexColor = (stringToVerify?: string | null): boolean => {
  const hexColorPattern = /^#([0-9A-Fa-f]{3}){1,2}$/

  if (!stringToVerify || stringToVerify?.length !== 7 || !hexColorPattern.test(stringToVerify)) {
    return false
  }
  return true
}

export const getChipColor = (color: ChipColor, customColor: string, theme: Theme): string => {

  const cleanColor = isValidHexColor(customColor) ? customColor : color

  switch (cleanColor) {
    case 'green':
      return '#DAF4D4'
    case 'orange':
      return '#FBE7D3'
    case 'darkorange':
      return '#ffd48f'
    case 'red':
      return '#e86666'
    case 'salmon':
      return '#FFC0BC'
    case 'pink':
      return '#FBD4F8'
    case 'grey':
      return '#EBEBEB'
    case 'yellow':
      return '#fff9a6'
    case 'purple':
      return '#D7CAFB'
    case 'lightblue':
      return '#D6ECEE'
    case 'blue':
      return '#D4E0FD'
    case 'primary':
      return theme.palette.primary.main
    case 'secondary':
      return theme.palette.secondary.main
    default:
      return cleanColor
  }
}
